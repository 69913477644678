@font-face {
  font-family: "octosquares-regular";
  font-style: normal;
  font-weight: normal;
  src: local("octosquares-regular"), url("./fonts/octosquares-regular.woff");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* home page styles */

html,
body {
  min-height: 100%;
  overflow: auto;
}

.landing-wrapper {
  background: url("./images/home-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100%;
  height: 100%;
}

.container {
  width: 100%;
  max-width: 1640px;
  box-sizing: border-box;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin: auto;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.bottom-content-footer {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  padding: 0.5rem;
}

.list-section {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  justify-content: center;
}

.harris-trump-img {
  max-width: 550px;
  width: 100%;
  height: auto;
}

.list-bar {
  font-size: 3.5rem;
  color: #fff;
  margin-left: 3.5rem;
  font-family: "octosquares-regular";
}

.footer-content {
  font-size: 2.25rem;
  color: #fff;
  font-family: "octosquares-regular";
  line-height: 2rem;
}

.bottom-left-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

/* contact page styles */

.bg-container {
  background-image: url("images/main-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100dvh;
  width: 100%;
  padding: 9.625rem 0 0 10rem;
  position: relative;
}

#thank-you-container {
  color: #ffffff;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

#response-error-container {
  color: red;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

@media screen and (max-width: 1199px) {
  .bg-container {
    padding: 1.5rem;
  }
}

.bg-container .content-container {
  max-width: 36.875rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 1rem;
}

@media screen and (max-width: 1199px) {
  .bg-container .content-container {
    margin: 0 auto;
  }
}

.bg-container .content-container h3 {
  font-size: 3.75rem;
  color: #ffffff;
  font-family: "octosquares-regular";
  margin-top: 0;
}

@media screen and (max-width: 991px) {
  .bg-container .content-container h3 {
    font-size: 2rem;
  }
  .list-bar {
    font-size: 2rem;
    margin-left: 1rem;
  }

  .footer-content {
    font-size: 1.25rem;
    line-height: 1rem;
  }
}

.bg-container .content-container input {
  padding: 1rem;
  border-radius: 2.375rem;
  border: 1px solid transparent;
  font-size: 1.5rem;
  font-family: "octosquares-regular";
  margin-top: 1.875rem;
  width: 100%;
  color: #ffffff;
  background: #732c73c2;
}

.bg-container .content-container input:focus-visible {
  border: 1px solid #a91fd2;
  outline: none;
}

.input-wrap {
  width: 100%;
}

.bg-container .logo-img img {
  width: 300px;
  position: fixed;
  bottom: 4rem;
  right: 4rem;
}

@media screen and (max-width: 1199px) {
  .bg-container .logo-img img {
    width: 150px;
    position: static;
    margin-top: 3rem;
    margin: 3rem auto;
  }
}

@media screen and (max-width: 1199px) {
  .bg-container .logo-img {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1199px) {
  .harris-trump-img {
    margin-left: auto;
  }
}

::-webkit-input-placeholder {
  color: #a999a9;
}

button {
  margin-top: 1.875rem;
  padding: 0.625rem 1rem;
  border-radius: 2.5rem;
  border: 3px solid #c932f1;
  font-size: 1.25rem;
  font-weight: 600;
  width: 100%;
  min-height: 3.188rem;
  color: #fff;
  background: transparent;
}

button:hover {
  background: linear-gradient(
    90deg,
    rgba(201, 50, 241, 1) 0%,
    rgba(116, 29, 139, 1) 100%
  );
  box-shadow: 0 0 20px 0 rgba(180, 91, 235, 0.9);
}

.hyperlink{
  margin-top: 1rem;
}
.sub-button:disabled{
  opacity: 0.5;
  cursor: not-allowed; 
}